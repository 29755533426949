import React, { useState } from 'react';
import { Button, Card, Modal, Text } from "../../components";
import { BaseColor, Images } from '../../config';
import { IsEmpty } from '../../config/appConstant';
import "./SimplyAddressCard.css";
import BulkUploadInstructions from '../../screens/SimplyNoted/BulkUploadInstructions';

const SimplyAddressCard = ({ SelectedAddresses, setNewAddressSaveBtn, IsSingaleAddress, setnewAddressEditID, SelectedSingaleAddresses, heading, constantData, isButtonShow, handleOnClick, handleOpenChangeLocation, bulkPurchase, handleOpenAddressBook, handleOpenNewAddress }) => {
    const [BulkUploadInstructionsOpen, setBulkUploadInstructionsOpen] = useState(false)

    const handleSingaleAddressEdit = (EditID) => {
        handleOpenNewAddress(true)
        setNewAddressSaveBtn(false)
        setnewAddressEditID(EditID)
    }

    return (
        <>
            <Card style={{ borderRadius: 15, }} elevation={0} CardColor={BaseColor.cardDarkbgcolor} >
                <div className="SimplyAddressCard-inner-padding">
                    <div>
                        <Text headline bold>{heading}</Text>
                    </div>
                    {bulkPurchase ?
                        <>
                            <div style={{ marginTop: "8px", lineHeight: "1", color: '#363333' }}>
                                <Text semibold body2>{constantData?.L1}</Text><br />
                                <Text semibold body2>{constantData?.L2}</Text>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                                <div style={{ color: '#363333' }}>
                                    <Text semibold body2>{constantData?.L3}</Text>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    {IsEmpty(isButtonShow) ?
                                        <Button variant='outlined'
                                            // className='Simplybutton'
                                            title={'Edit'}
                                            style={{
                                                TextTransform: "none", fontWeight: 400, fontSize: "14px", padding: "18px 22px", borderRadius: "5px", textAlign: "center", backgroundColor: "white"
                                            }}
                                            onClick={(e) => handleOnClick(constantData.IsAllLocation, constantData.ID)} /> : <></>}
                                </div>
                            </div>
                            {constantData?.isChangelocation && <div style={{ cursor: 'pointer', display: "flex" }} onClick={() => handleOpenChangeLocation(true)}>
                                <img src={Images.refresh_IC} alt='' />
                                &nbsp;&nbsp;
                                <div style={{ textDecoration: "underline", color: "#0070e4" }}><Text medium ThirdButtonColor body1>Change Return Address</Text></div>
                            </div>}
                        </>
                        :
                        <>
                            {SelectedAddresses.length != 0 ?
                                (SelectedAddresses.length == 1) ?
                                    <>
                                        <div style={{ marginTop: "8px", lineHeight: "1", color: '#363333' }}>
                                            <Text semibold body2>{SelectedSingaleAddresses[0].FirstName}</Text>&nbsp;
                                            <Text semibold body2>{SelectedSingaleAddresses[0].LastName}</Text><br />
                                            <Text semibold body2>{SelectedSingaleAddresses[0].Address}</Text>
                                            {!IsEmpty(SelectedSingaleAddresses[0].Address2) && <Text semibold body2>{`, ` + SelectedSingaleAddresses[0].Address2}</Text>}
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                                            <div style={{ color: '#363333' }}>
                                                <Text semibold body2>{SelectedSingaleAddresses[0].City}, {SelectedSingaleAddresses[0].State} {SelectedSingaleAddresses[0].Zip}</Text>
                                            </div>
                                            <div style={{ textAlign: 'right' }}>
                                                {/* // className='Simplybutton' */}
                                                {IsEmpty(isButtonShow)
                                                    ? <Button variant='outlined' title={'Edit'}
                                                        style={{ TextTransform: "none", fontWeight: 400, fontSize: "14px", padding: "18px 22px", borderRadius: "5px", textAlign: "center", backgroundColor: "white" }}
                                                        onClick={(e) => handleSingaleAddressEdit(SelectedSingaleAddresses[0].ID)} />
                                                    : <></>}
                                            </div>
                                        </div>
                                        <div style={{ cursor: 'pointer', display: "flex" }} onClick={() => handleOpenAddressBook(true)}>
                                            <img src={Images.refresh_IC} alt='' />
                                            &nbsp;&nbsp;
                                            <div style={{ textDecoration: "underline", color: "#0070e4" }}><Text medium ThirdButtonColor body1>Change Recipient  Address</Text></div>
                                        </div>
                                    </>
                                    : <div div style={{ color: "#363333", paddingTop: '10px' }}>
                                        <Text medium body1>&#x2022; {SelectedAddresses.length} Addresses Selected </Text>
                                    </div>
                                : !IsSingaleAddress &&
                                <div>
                                    <div style={{ textDecoration: "underline", color: "#0070e4", cursor: "pointer" }}>
                                        <a href='/Simply_Noted_Bulk_Address_Template.csv' download>
                                            <Text medium ThirdButtonColor body1>Download Bulk Order Templates </Text>
                                        </a>
                                    </div>
                                    <div style={{ textDecoration: "underline", color: "#0070e4", cursor: "pointer" }} onClick={() => setBulkUploadInstructionsOpen(true)}>
                                        <Text medium ThirdButtonColor body1>View Bulk Upload Instructions </Text>
                                    </div>
                                </div>}

                            {SelectedAddresses.length != 1 && <div style={{ marginTop: "25px", textAlign: "center", fontWeight: 500 }} >
                                {/* // className='selectbutton' */}
                                <Button variant='outlined' title="Select From Address Book" onClick={() => handleOpenAddressBook(true)}
                                    style={{ TextTransform: "none", fontWeight: 400, fontSize: "14px", padding: "18px 22px", borderRadius: "5px", textAlign: "center", backgroundColor: "white" }} />
                            </div>}
                        </>}
                </div>
            </Card>
            <Modal handleClose={() => setBulkUploadInstructionsOpen(false)} show={BulkUploadInstructionsOpen}>
                <div style={{ width: "860px" }}>
                    <BulkUploadInstructions />
                </div>
            </Modal>
        </>
    );
};

export default SimplyAddressCard;
