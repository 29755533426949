import React, { useState } from 'react';

// Sample data
const initialData = [
    { Title: 'New Year Campaign1', Description: ['1500 postcards mailed', '2000 postcards mailed'] },
    { Title: 'New Year Campaign2', Description: ['1000 postcards mailed', '1200 postcards mailed', '1300 postcards mailed'] },
];

function App() {
    const [data, setData] = useState(initialData);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingDescriptionIndex, setEditingDescriptionIndex] = useState(null);

    // Add new campaign
    const handleAddCampaign = () => {
        if (title) {
            const newData = [...data, { Title: title, Description: [] }];
            setData(newData);
            setTitle('');
        }
    };

    // Edit campaign title
    const handleEditCampaignTitle = (index) => {
        setEditingIndex(index);
        setTitle(data[index].Title);
    };

    // Save edited campaign title
    const handleSaveCampaignTitle = () => {
        const newData = [...data];
        newData[editingIndex].Title = title;
        setData(newData);
        setTitle('');
        setEditingIndex(null);
    };

    // Add description to a campaign
    const handleAddDescription = (index) => {
        if (description) {
            const newData = [...data];
            newData[index].Description.push(description);
            setData(newData);
            setDescription('');
        }
    };

    // Edit description of a campaign
    const handleEditDescription = (campaignIndex, descIndex) => {
        setEditingDescriptionIndex(descIndex);
        setDescription(data[campaignIndex].Description[descIndex]);
    };

    // Save edited description
    const handleSaveDescription = (campaignIndex) => {
        if (description) {
            const newData = [...data];
            newData[campaignIndex].Description[editingDescriptionIndex] = description;
            setData(newData);
            setDescription('');
            setEditingDescriptionIndex(null);
        }
    };

    // Delete description from a campaign
    const handleDeleteDescription = (campaignIndex, descIndex) => {
        const newData = [...data];
        newData[campaignIndex].Description.splice(descIndex, 1);
        setData(newData);
    };

    // Delete campaign
    const handleDeleteCampaign = (campaignIndex) => {
        const newData = [...data];
        newData.splice(campaignIndex, 1);
        setData(newData);
    };

    return (
        <div>
            <h1>Postcards Campaigns</h1>

            {/* Add new campaign */}
            <div>
                <h2>{editingIndex === null ? 'Add New Campaign' : 'Edit Campaign Title'}</h2>
                <input
                    type="text"
                    placeholder="Campaign Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <button onClick={editingIndex === null ? handleAddCampaign : handleSaveCampaignTitle}>
                    {editingIndex === null ? 'Add Campaign' : 'Save Title'}
                </button>
            </div>

            {/* Display campaigns */}
            <div>
                <h2>Campaign List</h2>
                <ul>
                    {data.map((campaign, campaignIndex) => (
                        <li key={campaignIndex}>
                            {/* Campaign Title */}
                            <strong>
                                {editingIndex === campaignIndex ? (
                                    <>
                                        <input
                                            type="text"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <button onClick={handleSaveCampaignTitle}>Save Title</button>
                                    </>
                                ) : (
                                    <>
                                        {campaign.Title}
                                        <button onClick={() => handleEditCampaignTitle(campaignIndex)}>Edit Title</button>
                                        <button onClick={() => handleDeleteCampaign(campaignIndex)}>Delete Campaign</button>
                                    </>
                                )}
                            </strong>

                            {/* Descriptions List */}
                            <ul>
                                {campaign.Description.map((desc, descIndex) => (
                                    <li key={descIndex}>
                                        {editingDescriptionIndex === descIndex ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />
                                                <button onClick={() => handleSaveDescription(campaignIndex)}>Save Description</button>
                                            </>
                                        ) : (
                                            <>
                                                {desc}
                                                <button onClick={() => handleEditDescription(campaignIndex, descIndex)}>
                                                    Edit Description
                                                </button>
                                                <button onClick={() => handleDeleteDescription(campaignIndex, descIndex)}>
                                                    Delete Description
                                                </button>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>

                            {/* Add description */}
                            <div>
                                <input
                                    type="text"
                                    placeholder="Add Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                <button onClick={() => handleAddDescription(campaignIndex)}>Add Description</button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default App;
