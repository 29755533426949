import React from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import ButtonStyle from './Buttontyle';
import { BaseColor } from '../../config';

const   ButtonBase = props => {
  const classes = ButtonStyle();
  const { IsforntSmall, title, onClick, type, ButtonType, full, endIcon, small, extraSmall, Medium, Large, customsize, customClass, round, startIcon, disabled, boxShadow, variant, loading } = props;

  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      className={`${classes.default} ${customClass} ${round && classes.round} ${small && classes.small} ${extraSmall && classes.extraSmall} ${Medium && classes.Medium} ${Large && classes.Large} ${customsize && classes.customsize} ${variant === 'contained' ? classes.textDefaultContained : classes.textDefaultOutline}`}
      fullWidth={full}
      variant={variant}
      type={ButtonType}
      onClick={onClick}
      disabled={loading ? true : disabled}
      style={{
        backgroundColor: disabled
          ? BaseColor.grayColor
          : variant == 'outlined'
            ? ''
            : type === 1
              ? BaseColor.mainButtonColor
              : type === 2
                ? BaseColor.SecoundButtonColor
                : type === 3
                  ? BaseColor.ThirdButtonColor
                  : type === 4
                    ? BaseColor.closeBtnColor
                    : type === 5
                      ? BaseColor.grayColor
                      : type === 6
                        ? BaseColor.ThirdButtonColor
                        : BaseColor.ThirdButtonColor,
        fontSize: IsforntSmall && '11px',
        boxShadow: boxShadow ? '' : 'none'
      }}
      {...props}>
      {loading && <CircularProgress color={BaseColor.mainButtonColor} size={15} style={{ marginRight: '5px' }} />}
      {title}
    </Button>
  );
};
ButtonBase.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  startIcon: PropTypes.node,
  variant: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  full: PropTypes.bool,
  round: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  boxShadow: PropTypes.bool,
  Medium: PropTypes.bool,
  Large: PropTypes.bool,
  customsize: PropTypes.bool,
  extraSmall: PropTypes.bool,
};

ButtonBase.defaultProps = {
  customClass: {},
  startIcon: null,
  variant: 'contained',
  full: false,
  round: false,
  type: 1,
  small: false,
  disabled: false,
  boxShadow: true,
  title: '',
  Medium: false,
  Large: false,
  customsize: false,
  extraSmall: false,
  loading: false,
};
export default ButtonBase;
