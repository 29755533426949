import React, { useState } from 'react';
import PropTypes from "prop-types";
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight } from '../../config';

const CssTextField = withStyles({
    root: {
        '& label': {
            color: BaseColor.inputLableColor,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.bold,
            letterSpacing: '0.5px'
        },
        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px'
        },
        '& label.Mui-focused': {
            color: BaseColor.inputMainColor,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px'
            }
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: BaseColor.inputBorderColor,
                color: "red"
            },
            '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.inputMainColor
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.inputMainColor
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 15px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.inputLableColor,
                fontWeight: FontWeight.regular
            }
        }
    }
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    TextFieldStyle: {
        width: "100%",
        fontSize: 15,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.bold,
        borderRadius: '8px'
    }
}));

const CustomInputComponent = props => {
    const { value, name, onChange, error, handleKeyPress, label, } = props;

    const classes = useStyles();

    // const handleKeyPress = (event) => {

    // if (event.key === 'Backspace') {
    //     event.preventDefault();

    //     const input = event.target.value;
    //     const cursorPosition = event.target.selectionStart;

    //     // If the cursor is not at the start of the input, delete the character before it
    //     if (cursorPosition > 0) {
    //         const newValue = input.slice(0, cursorPosition - 1) + input.slice(cursorPosition)
    //         if (newValue == "") {
    //             setFormValue({ ...formvalue, [name]: "" });
    //             setError({ ...Error, [name]: true });

    //             // setIsDataChange(false)
    //         } else if (newValue.length != 14) {
    //             setFormValue({ ...formvalue, [name]: newValue });
    //             setError({ ...Error, [name]: true });

    //             // setIsDataChange(false)
    //         } else {
    //             setFormValue({ ...formvalue, [name]: newValue });
    //             setError({ ...Error, [name]: false });
    //             // setPhoneNumberValue(NumberValidation(e));

    //             // setIsDataChange(true)
    //         }
    //         setTimeout(() => {
    //             event.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
    //         }, 0);
    //     }
    // }
    // };

    return (
        <div className={classes.root}>
            <CssTextField
                error={error}
                className='fromcantrol-input'
                label={label}
                name={name}
                onKeyDownCapture={handleKeyPress}
                variant="outlined"
                value={value}
                onChange={onChange}
                {...props} />
        </div>
    );
};

CustomInputComponent.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.bool,
};

CustomInputComponent.defaultProps = {
    style: {},
    maxLength: 15000,
    value: '',
    name: '',
    label: '',
    variant: 'outlined',
    onChange: () => { },
    required: false,
    error: false,
};

export default CustomInputComponent;
