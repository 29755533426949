import React, { useEffect, useRef, useState } from 'react';
import { URLs } from '../../API/URLs';
import { IsEmpty } from '../../config/appConstant';

const MapWithMarkers = React.memo(({ placeId, allPlaceIds }) => {
  const mapRef = useRef(null);
  const apiKey = URLs.googlekey; // Your API key
  const staticPlaceId = "ChIJCzYy5IS16lQRQrfeQ5K5Oxw";
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const loadMap = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setMapLoaded(true);
    };

    loadMap();
  }, [apiKey]);

  useEffect(() => {
    if (!mapLoaded) return; // Ensure the map has loaded

    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 0, lng: 0 },
      zoom: IsEmpty(placeId) && IsEmpty(allPlaceIds) ? 4 : 10,
    });

    const bounds = new window.google.maps.LatLngBounds();

    const addMarkers = async () => {
      const markersPromises = [];
      const fetchLocationData = async (id) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${id}&key=${apiKey}`);
        return response.json();
      };

      // Check if both placeId and allPlaceIds are empty
      const finalPlaceId = IsEmpty(placeId) && IsEmpty(allPlaceIds) ? staticPlaceId : placeId;

      if (IsEmpty(placeId) && !IsEmpty(allPlaceIds)) {
        for (const id of allPlaceIds) {
          if (id) {
            markersPromises.push(fetchLocationData(id));
          }
        }

        const results = await Promise.all(markersPromises);
        results.forEach(data => {
          if (data.results.length) {
            const location = data.results[0].geometry.location;
            new window.google.maps.Marker({
              position: location,
              map,
              title: 'Location',
            });
            bounds.extend(location);
          }
        });
        map.fitBounds(bounds);
      } else {
        const data = await fetchLocationData(finalPlaceId);
        if (data.results.length) {
          const location = data.results[0].geometry.location;
          !IsEmpty(placeId) && IsEmpty(allPlaceIds) &&
            new window.google.maps.Marker({
              position: location,
              map,
              title: 'Selected Location',
            });
          map.setCenter(location);
        }
      }
    };

    addMarkers();
  }, [mapLoaded, placeId, allPlaceIds, apiKey]);

  return <div ref={mapRef} style={{ height: '100%' }} />;
});

export default MapWithMarkers;
