import { BaseColor } from "../../config";
export default {
  pageSubtitleMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // margin: '0px 25px',
    margin: '0px 0px',
    border: '0',
  },
  pageSubtitleborderbotttom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 54px',
    borderBottom: '1px solid',
    borderBottomColor: BaseColor.dividerColor,
    borderTop: '1px solid',
    borderTopColor: BaseColor.dividerColor,
    width: '92%'
  },
  ViewArtworkpopupborderbotttom: {
    borderBottom: '1px solid',
    borderBottomColor: BaseColor.dividerColor,
    borderTop: '1px solid',
    borderTopColor: BaseColor.dividerColor,
  },
  iconsIndex: {
    paddingRight: '12px',
    margin: '2px 0',
    height: 35,
    borderRight: '1px solid',
    borderRightColor: BaseColor.dividerColor,
  },
  Icons: {
    padding: '8px',
    height: 42,
  },
  flexSection: {
    display: 'flex',
    width: '29%',
  },
  LeftflexSection: {
    width: '40%',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '20px'
  },
  flexSectionAlignment: {
    display: 'flex',
    alignItems: 'center',
  },
  IconButtonPadding: {
    padding: 2,
    // height: "50px",
    // width: '24px'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  textAlignRight: {
    textAlign: 'Right'
  },
  JustifyAlignRight: {
    justifyContent: 'flex-end'
  },
  JustifyAlignCenter: {
    justifyContent: 'center'
  },
  JustifyAlignSpaceEvenly: {
    // justifyContent: 'center'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  subtitlebutton1: {
    marginRight: "20px"
  },
  subtitlebutton2: {
    marginRight: "50px",
  },
  subtitlebutton2withPadding: {
    marginRight: "50px",
    padding: '5px 6px 5px 18px',
  },
  subtitlebutton1withPadding: {
    padding: '5px 6px 5px 18px',
  },
  subtitlebutton3: {
    // marginRight: "50px",
    margin: "5px 10px",
  },
  startsection: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // paddingLeft: '20px'
  },
  leststartsection: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '20px'
  },
  ViewArtworkpopupleststartsection: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  middlesection: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ViewArtworkpopupmiddlesection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  endsection: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
  }
}