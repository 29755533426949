import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight } from '../../config';

const CssTextField = withStyles({
  root: {
    '& label': {
      color: BaseColor.inputLableColor,
      marginTop: 1,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.bold,
      letterSpacing: '0.5px',
      textOverflow: "ellipsis",
      whiteSpace: 'nowrap',
      overflowx: 'hidden'
    },
    '& .MuiFormLabel-root': {
      lineHeight: 0.4,
      fontSize: '13.9px'
    },
    '& label.Mui-focused': {
      color: BaseColor.inputMainColor,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.heavy
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -2px) scale(0.79)',
        fontSize: '15px'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: BaseColor.inputBorderColor,
        color: "red"
      },
      '&:hover fieldset': {
        borderWidth: '1px',
        borderColor: BaseColor.inputMainColor
      },
      '&.Mui-disabled fieldset': {
        borderColor: BaseColor.inputBorderColor, // Custom border color for disabled state
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: BaseColor.inputMainColor
      },
      '& .MuiOutlinedInput-input': {
        padding: '14.5px 15px',
        fontFamily: FontFamily.FontFamily_1,
        color: BaseColor.inputLableColor,
        fontWeight: FontWeight.regular,
        overflow: 'auto', // Ensures content is visible if it overflows
        resize: 'vertical' // Allows vertical resizing if needed
      }
    }
  }
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  TextFieldStyle: {
    width: "100%",
    fontSize: 15,
    fontFamily: FontFamily.FontFamily_1,
    fontWeight: FontWeight.heavy,
    borderRadius: '8px',
    backgroundColor: BaseColor.whiteColor
  }
}));

const CustomInputComponent = props => {
  const { value, onChange, error, label, name, maxLength, isDisabled } = props

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssTextField
        disabled={isDisabled ? true : false}
        className='fromcantrol-input'
        error={error}
        label={label}
        variant="outlined"
        name={name}
        value={value}
        onChange={onChange}
        inputProps={{
          maxLength: maxLength, // Limit maximum length to 10 characters
        }}
      />
    </div>
  )
}
export default CustomInputComponent;