import React, { createContext, useState } from 'react';

const MyContext = createContext();

const MyProvider = ({ children }) => {

    const [cartCount, setCartCount] = useState("0");
    const [notiCount, setnotiCount] = useState("0");
    const [totalNotiCount, settotalNotiCount] = useState("0");

    const updateCartCount = (count) => {
        setCartCount(count);
    };
    const updateNotiCount = (count) => {
        setnotiCount(count);
    };
    const updateTotalNotiCount = (count) => {
        settotalNotiCount(count);
    };

    return (
        <MyContext.Provider value={{ cartCount, updateCartCount, notiCount, updateNotiCount, totalNotiCount, updateTotalNotiCount }}>
            {children}
        </MyContext.Provider>
    );
};

export { MyContext, MyProvider };