import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight } from '../../config';

const CssTextField = withStyles({
  root: {
    '& label': {
      color: BaseColor.inputLableColor,
      marginTop: 1,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.bold,
      letterSpacing:'0.5px'
    
    },

    '& .MuiFormLabel-root': {
      lineHeight: 0.4,
      fontSize: '13.9px'
    },

    '& label.Mui-focused': {
      color: BaseColor.inputMainColor,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.heavy,

    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -2px) scale(0.79)',
        fontSize: '15px'
      },

    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: BaseColor.inputBorderColor,
        color:"red"
        

      },
      '&:hover fieldset': {
        borderWidth: '1px',
        borderColor: BaseColor.inputMainColor
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: BaseColor.inputMainColor
      },
      '& .MuiOutlinedInput-input': {
        padding: '14.5px 15px',
        fontFamily: FontFamily.FontFamily_1,
        color:BaseColor.inputLableColor,
        fontWeight:FontWeight.regular
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  TextFieldStyle: {

    width: "100%",
    fontSize: 15,
   
    fontFamily: FontFamily.FontFamily_1,
    fontWeight: FontWeight.heavy,
    borderRadius: '8px',
    backgroundColor:BaseColor.inputBgColor

  },

}));
export default function DateTimePiker(props) {
    const {value,onChange,error,label,name,disabled} =props
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssTextField
          error={error }
            className='fromcantrol-input'
                type='datetime-local'
                label={label}
                variant="outlined"
                name={name}
                disabled={disabled}
                value={value}
                onChange={onChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    )
}
